import { useState } from 'react';
import {
  AppShell,
  Aside,
  Avatar,
  Box,
  Burger,
  Button,
  Divider,
  Footer,
  Group,
  Header,
  List,
  MediaQuery,
  Menu,
  Modal,
  Navbar,
  SegmentedControl,
  Text,
  TextInput,
  ThemeIcon,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';

import { useDisclosure } from '@mantine/hooks';
import { useDebouncedState } from '@mantine/hooks';

import {
  Download,
  OpenInNew,
  Search,
} from '@mui/icons-material';

import data_raw from './data/json/data.json';
import data_srt from './data/json/data_srt.json';
import RBdata from './data/json/FULL_RB.json';

import Login from './login';

export default function AppShellDemo() {
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  const [modalOpened, { open, close }] = useDisclosure(false);
  const [item, setItem] = useState('');
  const [query, setQuery] = useDebouncedState('', 200);
  const [sortBy, setSortBy] = useState('RB');
  //const [debounced] = useDebouncedValue(query, 200);

  const data = sortBy == 'RB' ? data_raw : data_srt;

  const openDialog = (item) => {
    setItem(item);
    open();
  }

  const matchesQuery = (item) => {
    if(query == '') return true;
    return (item.name.toLowerCase() + item.artist.toLowerCase()).includes(query.toLowerCase());
  }

  const navLinks = [
    {
      name: 'Polyband Website',
      icon: <OpenInNew/>,
      uri: 'https://polyband.ch',
    }
  ];

  const colors = {
    RB1: 'red',
    R15: 'pink',
    RB2: 'blue', 
    RB3: 'yellow',
  }

  return (
    <AppShell
      styles={{
        main: {
          background: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
        },
      }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      navbar={
        <Navbar p="md" hiddenBreakpoint="sm" hidden={!opened} width={{ sm: 200, lg: 300 }}>
          <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
            <Box sx={{ flex: 1 }}>
              {navLinks.map(link => (
                <UnstyledButton
                  component="a"
                  href={link.uri}
                  target="_blank"
                  sx={(theme) => ({
                    display: 'block',
                    width: '100%',
                    padding: '10px',
                    borderRadius: '10px',

                    '&:hover': {
                      backgroundColor: '#f9c7c9',
                    },
                  })}
                >
                  <Group sx={{ flex: 1 }}>
                    {link.icon}
                    <Text>{link.name}</Text>
                  </Group>
                </UnstyledButton>
              ))}
              <Divider my="sm" />
              <Box>
                {RBdata.map(RB => (
                  <Menu shadow="md" width={200}>
                    <Menu.Target>
                      <UnstyledButton
                        sx={(theme) => ({
                          display: 'block',
                          width: '100%',
                          padding: '10px',
                          borderRadius: '10px',

                          '&:hover': {
                            backgroundColor: '#f9c7c9',
                          },
                        })}
                      >
                        <Group sx={{ flex: 1 }}>
                          <Avatar color={colors[RB.slug]} variant="light">{ RB.slug }</Avatar>
                          <Text>{RB.name}</Text>
                        </Group>
                      </UnstyledButton>
                    </Menu.Target>

                    <Menu.Dropdown>
                      <Menu.Label>Keys</Menu.Label>
                      {RB.keys.map(key => (
                        <Menu.Item component="a" href={key.file} target="_blank" icon={<Download/>}>{key.key}</Menu.Item>
                      ))}
                    </Menu.Dropdown>
                  </Menu>
                ))}
              </Box>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <Group>
                <h2 style={{ color: '#ed1c24' }}>About</h2>
                <p style={{ margin: 0, marginTop: '-20px', width: '100%' }}>
                  © Polyband der ETH und Universität Zürich, 2023
                </p>
                <p style={{ margin: 0, marginTop: '-10px', width: '100%' }}>
                  8092 Zürich 
                </p>
                <p style={{ margin: 0, marginTop: '-10px', width: '100%' }}>
                  <a href="https://polyband.ch" style={{ color: 'black' }}>www.polyband.ch</a>
                </p>
                <p style={{ margin: 0, marginTop: '10px', width: '100%', fontSize: 12 }}>
                  The Source code of this application can be found on <a href="https://gitlab.ethz.ch/polyband/leadsheet-archive" style={{ color: 'black' }}>https://gitlab.ethz.ch/polyband/leadsheet-archive</a> and is distributed under the GNU AGPL 3.0.
                </p>
              </Group>
            </Box>
          </Box>
        </Navbar>
      }
      header={
        <Header height={{ base: 70, md: 70 }} p="md">
          <div style={{ display: 'flex', height: '100%', alignItems: 'center' }}>
            <MediaQuery largerThan="sm" styles={{ display: 'none' }} sx={{ flex: 1 }}>
              <Burger
                opened={opened}
                onClick={() => setOpened((o) => !o)}
                size="sm"
                color={theme.colors.gray[6]}
                mr="xl"
              />
            </MediaQuery>
            <img src="logo_wide.svg" style={{ maxHeight: 50 }} alt="Polyband Logo"/>
          </div>
        </Header>
      }
    >
      <h1>Leadsheet Archive</h1> 

      <TextInput
        label="Search"
        defaultValue={query}
        onChange={(event) => setQuery(event.currentTarget.value)}
        icon={<Search/>}
        size="md"
        sx={{ marginTop: '20px', marginBottom: '20px' }}
      />

      <SegmentedControl
        value={sortBy}
        onChange={setSortBy}
        data={[
          { label: 'Sort by Book', value: 'RB' },
          { label: 'Sort by Alphabet', value: 'ABC' },
        ]}
        sx={{ marginBottom: '20px', marginTop: '10px' }}
        radius="md"
        size="md"
      />

      <List
        spacing="xs"
        size="sm"
        center
      >
        {data.filter(item => matchesQuery(item)).map(item => (
          <List.Item style={{ cursor: 'pointer' }} onClick={() => openDialog(item)} icon={ <Avatar color={colors[item.RB]} variant="light">{ item.RB }</Avatar> }>{item.name} {item.artist && <span style={{ color: "#aaaaaa" }}>({item.artist})</span>}</List.Item>
        ))}
      </List>
      <Modal sx={{ padding: '5vh 0px' }} opened={modalOpened} onClose={close} title={<Avatar color={colors[item.RB]} variant="light">{ item.RB }</Avatar>} centered sx={{ margin: 'auto' }}>
        <Box>
          <Box sx={{ marginBottom: '20px' }}>
            <h3 style={{ marginBottom: '0px', marginTop: '0px' }}>{item.name}</h3>
            {item.artist && <span style={{ color: '#aaaaaa' }}>by {item.artist}</span>}
          </Box>
          <Group spacing={5}>
            {item.keys && item.keys.map(key => (
              <Button leftIcon={<Download/>} component="a" href={"/pdf/" + key + "_" + item.filename} target="_blank" color={colors[item.RB]} variant="filled">{key}</Button>
            ))}
          </Group>
        </Box>
      </Modal>
      <Login/>
    </AppShell>
  );
}
