import {useState, useEffect} from 'react';

import {
  Button,
  Group,
  Modal,
  TextInput,
} from '@mantine/core';

import { hashString } from 'react-hash-string';

const Login = (props) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [password, setPassword] = useState('');

  const testPassword = () => {
    if(hashString(password) == '1084079815' || hashString(password) == '111357' || hashString(password) == '1326931525') setLoggedIn(true);
    return;
  }
  
  useEffect(() => {
    testPassword();
  }, [password]);

  return (
    <>
      <Modal opened={!loggedIn} centered withCloseButton={false}>
        <h2 style={{ marginTop: '0px' }}>Please Enter Password</h2>
        <TextInput
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          label="Password"
        />
      </Modal>
    </>
  );
}

export default Login;
